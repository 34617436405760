<template>
  <div class="edit-ticket">
    <h1 class="admin-title">{{$t('Edit Ticket')}}</h1>
    <form @submit.prevent>
      <b-row>
        <b-col>
          <RFInput label="Item Name" v-model="ticket.itemName" readonly/>
        </b-col>
        <b-col>
          <RFInput label="Item Code" v-model="ticket.itemCode" readonly/>
        </b-col>
        <b-col>
          <RFSelect top-label="Status" @input="ticket.status = $event" :value="ticket.status" :list="ticketStatusList"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <RFInput label="Firstname" v-model="ticket.userFirstname" readonly/>
        </b-col>
        <b-col>
          <RFInput label="Lastname" v-model="ticket.userLastname" readonly/>
        </b-col>
        <b-col>
          <RFInput label="Title" v-model="ticket.title"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <RFInput label="Description" v-model="ticket.description"/>
        </b-col>
        <b-col>
          <RFDatePicker label="Open Date" v-model="ticket.openDate" readonly/>
        </b-col>
        <b-col>
          <RFDatePicker label="Closed Date" v-model="ticket.closedDate"/>
        </b-col>
      </b-row>

      <b-row class="justify-content-end mt-5">
        <b-col cols="auto">
          <RFButton class="mt-3" label="Save" background="#5CB85C" @on-click="save" v-if="!loading"/>
          <RFLoader class="mt-3" color="#24425B" :size="30" v-else/>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import RFTextArea from '@/components/forms/RFTextArea'
import RFDatePicker from '@/components/forms/RFDatePicker'
import RFSelect from '@/components/forms/RFSelect'

import { ticketService } from '@/services/admin'
import { ticketStatus } from '@/enums'

import { mapState } from 'vuex'

export default {
  name: 'AdminEditTicket',
  components: {
    RFInput,
    RFButton,
    RFTextArea,
    RFLoader,
    RFSelect,
    RFDatePicker,
  },
  data() {
    return {
      ticket: null,
      loading: false,
      ticketStatusList: [],
    }
  },
  computed: {
    ...mapState({
      storedTicket: state => state.adminTicketStore.ticket,
    })
  },
  beforeMount() {
    this.ticket = { ...this.storedTicket }
    this.ticketStatusList = Object.keys(ticketStatus).map(key => ({ value: key, label: key }))
  },
  methods: {
  async save() {
      this.loading = true
      const response = await ticketService.editTicket(this.ticket)

      if (response.status === 200) {
        this.$toasted.success(this.$i18n.t(`Ticket successfully edited!`))
      } else {
        this.$toasted.error(this.$i18n.t(`Error during edit ticket`))
      }

      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>